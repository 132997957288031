






































import Vue from 'vue';
import { computed } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import cmsImageToUrl from '~/helpers/cms/cmsImageToUrl';
import { CmsImageResponse } from '~/types/cms/CmsImageResponse';

export default Vue.extend({
  name: 'MarketingCardWide',
  props: {
    backgroundDesktop: {
      type: Object as PropType<CmsImageResponse>,
      default: () => {
        return {};
      },
    },
    backgroundMobile: {
      type: Object as PropType<CmsImageResponse>,
      default: () => {
        return {};
      },
    },
    headline: {
      type: String,
      required: true,
    },
    strapline: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    callToActionText: {
      type: String,
      default: '',
    },
    callToActionSlug: {
      type: String,
      default: '',
    },
  },
  setup(props, { root: { $router } }) {
    const mobileImageUrl = computed(() => cmsImageToUrl(props.backgroundMobile as CmsImageResponse));
    const desktopImageUrl = computed(() => cmsImageToUrl(props.backgroundDesktop as CmsImageResponse));
    const openCallToActionLink = () => {
      $router.push(props.callToActionSlug);
    };
    return {
      mobileImageUrl,
      desktopImageUrl,
      openCallToActionLink,
    };
  },
});

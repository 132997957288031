import { shallowMount } from '@vue/test-utils';
import MarketingCardWide from '../MarketingCardWide';

jest.mock('~/helpers/cms/cmsImageToUrl');

function createWrapper() {
  return shallowMount(MarketingCardWide, {
    propsData: {
      headline: 'title',
      strapline: 'subtitle',
      label: 'label',
      callToActionText: 'learn more',
      callToActionSlug: 'call to action slug',
      backgroundDesktop: {},
      backgroundMobile: {},
    },
  });
}

describe('MarketingCardWide.vue', () => {
  test('Renders correctly', () => {
    const wrapper = createWrapper();
    expect(wrapper.element).toMatchSnapshot();
  });
});
